<template>
  <b-card no-body>
    <b-card-body>
      <b-row>
        <b-col cols="12" md="4" sm="6" class="mb-md-0 mb-2">
          <label>Status</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="statusFilter"
            :options="statusOptions"
            class="w-100"
            :reduce="(val) => val.value"
            @input="(val) => $emit('update:statusFilter', val)"
            placeholder="Select Status.."
            multiple
          />
        </b-col>
        <b-col cols="12" md="4" sm="6" class="mb-md-0 mb-2">
          <label>Category</label>
          <b-form-select
            :value="categoryFilter"
            @input="(val) => $emit('update:categoryFilter', val)"
            :options="parentCategoryOption"
          ></b-form-select>
          <!-- <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="categoryFilter"
            :options="parentCategoryOption"
            class="w-100"
            :reduce="(val) => val.hashid"
            @input="(val) => $emit('update:categoryFilter', val)"
            placeholder="Select Category .."
          /> -->
        </b-col>
        <b-col cols="12" md="4" sm="6" class="mb-md-0 mb-2">
          <label>Karma Program</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="karmaProgramFilter"
            :options="karmaProgramOption"
            class="w-100"
            :reduce="(val) => val.hashid"
            @input="(val) => $emit('update:karmaProgramFilter', val)"
            placeholder="Select Karma Program .."
          />
        </b-col>
        <b-col cols="12" md="4" sm="6" class="mb-md-0 mb-2">
          <label>By Date</label>
          <flat-pickr
            :value="dateFilter"
            @input="(val) => $emit('update:dateFilter', val)"
            :config="{
              dateFormat: 'Y-m-d',
            }"
            class="form-control"
            placeholder="Pick Date.."
          />
        </b-col>
        <b-col cols="12" md="4" sm="6" class="mb-md-0 mb-2">
        <div v-if="employeeListOptions.length > 1">
          <label>By Reporting Manager</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="managerFilter"
            @input="(val) => $emit('update:managerFilter', val)"
            :options="employeeListOptions"
            class="w-100"
            :reduce="(val) => val.code"
            :clearable="true"
            placeholder="Select Employee.."
          />
        </div>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import { BCard, BCardBody, BRow, BCol } from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";

// Set the components prop default to return our fresh components
vSelect.props.components.default = () => ({
  Deselect: {
    render: (h) => h("feather-icon", { props: { size: "14", icon: "XIcon" } }),
  },
  OpenIndicator: {
    render: (h) =>
      h("feather-icon", {
        props: { size: "15", icon: "ChevronDownIcon" },
        class: "open-indicator",
      }),
  },
});

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    vSelect,
    flatPickr,
  },
  props: {
    statusFilter: {},
    statusOptions: {
      type: Array,
      required: true,
    },
    categoryFilter: {},
    parentCategoryOption: {
      required: true,
    },
    karmaProgramFilter: {},
    dateFilter: {},
    karmaProgramOption: {
      required: true,
    },
    managerFilter: {},
    employeeListOptions: {
      required: true,
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
